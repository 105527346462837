.public {
  .usernames {
    @include _(ph-xs);
    background: url("../../../../img/fundraiser_bg.png") no-repeat;
    background-size: cover;
    height: 120vh;
    display: block;
    overflow: hidden;
    opacity: 1;
    animation: fadeIn 1.5s ease 1 backwards;

    @include breakpoint(medium) {
      background: url("../../../../img/fundraiser_bg_h.jpg") no-repeat;
      background-size: cover;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &__thank_you {
      @include _(pv-s);
      @include _(ph-ms);
      margin: 3rem auto;
      width: 80%;
      background: rgba(#444, 1);
      border-radius: $radius;
      text-align: center;
      z-index: 9998;

      @include breakpoint(medium) {
        width: 35%;
        top: 5%;

        img {
          width: 50%;
        }
      }

      h1 {
        @include _(fw-xbold);
        color: $white;
      }

      p {
        @include _(fw-xbold);
        @include _(pt-s);
        @include _(pb-xs);
        @include _(mt-s);
        @include _(fs-ms);
        line-height: 2em;
        border-top: 3px solid #fff;
        color: $white;

        @include breakpoint(medium) {
          font-size: 1.776889rem !important;
          line-height: 2.25rem !important;
        }
      }
    }

    &__search_button {
      width: 80%;
      // height: 213px;
      margin: 0 auto;
      text-align: center;
      z-index: 9999;
      position: relative;

      @include breakpoint(medium) {
        width: 35%;
      }

      .tiltify {
        width: 35%;
        margin: 2rem auto 0;

        @include breakpoint(medium) {
          width: 20%;
        }
      }

      .rah-hint-wrapper {
        font-weight: 700 !important;
        // left: 104px !important; mac
        // left: 62px !important;
        font-family: "SJ Sans", "Open Sans", "Helvetica Neue", "Helvetica",
          "Arial", "sans-serif" !important;
        // top: 63px !important;
      }

      // &:before {
      //   content: "";
      //   position: absolute;
      //   // right: 118px; mac
      //   right: 3%;
      //   top: 95px;
      //   height: 50px;
      //   width: 50px;
      //   display: inline-block;
      //   margin-right: 10px;
      //   mask: url("../../../../img/search_glass.svg") no-repeat 50% 50%;
      //   mask-size: cover;
      //   background-color: $primary;
      //   z-index: 9999;
      // }

      input {
        &.search_field {
          @include _(fs-m);
          @include _(fw-bold);
          @include _(mt-ms);
          @include _(pv-xs);
          @include _(ph-xs);
          width: 100%;
          border-radius: $radius;
          border: 0px;
          // padding: 2rem 5rem 2rem 2rem;
          line-height: 2.25rem;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: $grey-10;
          }
        }
      }

      .validation {
        @include _(p-xxs);
        @include _(fw-bold);
        width: 80%;
        margin: 0 auto;
        background: rgba($grey-90, 0.8);
        color: $white;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
      }

      button {
        // display: none;
        @include _(pv-xs);
        @include _(ph-m);
        @include _(fs-ms);
        @include _(fw-xbold);
        color: $white;
        background-color: $link-color;
        border: 0;
        border-radius: $radius;
        margin: 25px auto 0;
      }
    }

    &__select {
      display: block;

      div {
        display: inline-block;
        // @include _(mv-s);
        @include _(mh-xxs);
        @include _(fs-ms);
        color: $white;
      }

      label {
        @include _(p-xs);
        @include _(fs-s);
        @include _(fw-bold);
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
        border-radius: $radius;

        &.active {
          background-color: $white;
          color: $grey-90;
        }
      }

      input {
        display: none;
      }

      span {
        @include _(fs-m);
      }
    }
  }

  @keyframes scrollUp {
    0% {
      transform: translate(0, 0%);
    }
    100% {
      transform: translate(0, -100%);
    }
  }
}
