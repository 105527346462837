.kiosk {
  .usernames {
    @include _(ph-xs);
    background: url("../../../../img/fundraiser_bg.png") no-repeat;
    height: 100vh;
    display: block;
    overflow: hidden;
    opacity: 1;
    animation: fadeIn 1.5s ease 1 backwards;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &__thank_you {
      @include _(pv-s);
      @include _(ph-ms);
      position: absolute;
      top: 18%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 55%;
      background: rgba(#444, 1);
      border-radius: $radius;
      text-align: center;
      z-index: 9999;

      h1 {
        @include _(fw-xbold);
        color: $white;
      }

      p {
        @include _(fw-xbold);
        @include _(pt-s);
        @include _(pb-xs);
        @include _(mt-s);
        @include _(fs-m);
        line-height: 2em;
        border-top: 3px solid #fff;
        color: $white;
      }
    }

    &__patients {
      z-index: 9998;
      height: 100vh;
      width: 100vw;
      position: absolute;

      div {
        position: relative;
        width: 30%;
        line-height: 0;
        opacity: 0;

        img {
          border: 5px solid $white;
          border-radius: $radius;
        }

        span {
          @include _(fs-xs);
          @include _(ph-xxs);
          border-bottom-left-radius: $radius;
          position: absolute;
          background: $white;
          left: 0;
          bottom: 0;
          line-height: 2em;
        }
      }
      .elizabeth {
        // transform: translate(100px, 100px) rotate(-10deg);
        // opacity: 1;
        animation: elizabeth 60s infinite;
        box-shadow: 3px 3px 10px #222;
      }

      @keyframes elizabeth {
        0% {
          transform: translate(100px, 100px) rotate(-10deg);
          opacity: 0;
        }
        5% {
          opacity: 1;
        }
        45% {
          transform: translate(100px, 60px) rotate(-10deg);
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          transform: translate(100px, 100px) rotate(-10deg);
          opacity: 0;
        }
      }

      .ava {
        animation: ava 60s 30s infinite;
        box-shadow: 3px 3px 10px #222;
        // transform: translate(100px, -777px) rotate(-10deg);
        // opacity: 1;
      }

      @keyframes ava {
        0% {
          transform: translate(100px, -777px) rotate(-10deg);
          opacity: 0;
        }
        5% {
          opacity: 1;
        }
        45% {
          transform: translate(100px, -817px) rotate(-10deg);
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          transform: translate(100px, -777px) rotate(-10deg);
          opacity: 0;
        }
      }

      .jacob {
        // transform: translate(650px, -105px) rotate(10deg);
        // opacity: 1;
        animation: jacob 60s 5s infinite;
        box-shadow: 3px 3px 10px #222;
      }

      @keyframes jacob {
        0% {
          transform: translate(650px, -105px) rotate(10deg);
          opacity: 0;
        }
        5% {
          opacity: 1;
        }
        45% {
          transform: translate(650px, -155px) rotate(10deg);
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          transform: translate(650px, -105px) rotate(10deg);
          opacity: 0;
        }
      }

      .gabriel {
        // transform: translate(650px, -983px) rotate(10deg);
        // opacity: 1;
        animation: gabriel 60s 35s infinite;
        box-shadow: 3px 3px 10px #222;
      }

      @keyframes gabriel {
        0% {
          transform: translate(650px, -983px) rotate(10deg);
          opacity: 0;
        }
        5% {
          opacity: 1;
        }
        45% {
          transform: translate(650px, -1033px) rotate(10deg);
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          transform: translate(650px, -983px) rotate(10deg);
          opacity: 0;
        }
      }

      .langston {
        // transform: translate(100px, 655px) rotate(-10deg);
        // opacity: 1;
        animation: langston 60s 10s infinite;
        box-shadow: 3px 3px 10px #222;
      }

      @keyframes langston {
        0% {
          transform: translate(100px, 730px) rotate(-10deg);
          opacity: 0;
        }
        5% {
          opacity: 1;
        }
        45% {
          transform: translate(100px, 655px) rotate(-10deg);
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          transform: translate(100px, 730px) rotate(-10deg);
          opacity: 0;
        }
      }

      .josie {
        // transform: translate(100px, -221px) rotate(-10deg);
        // opacity: 1;
        animation: josie 60s 40s infinite;
        box-shadow: 3px 3px 10px #222;
      }

      @keyframes josie {
        0% {
          transform: translate(100px, -146px) rotate(-10deg);
          opacity: 0;
        }
        5% {
          opacity: 1;
        }
        45% {
          transform: translate(100px, -221px) rotate(-10deg);
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          transform: translate(100px, -146px) rotate(-10deg);
          opacity: 0;
        }
      }

      .raven {
        // transform: translate(650px, 415px) rotate(10deg);
        // opacity: 1;
        animation: raven 60s 15s infinite;
        box-shadow: 3px 3px 10px #222;
      }

      @keyframes raven {
        0% {
          transform: translate(650px, 500px) rotate(10deg);
          opacity: 0;
        }
        5% {
          opacity: 1;
        }
        45% {
          transform: translate(650px, 415px) rotate(10deg);
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          transform: translate(650px, 500px) rotate(10deg);
          opacity: 0;
        }
      }
    }

    .lennon {
      // transform: translate(650px, -462px) rotate(10deg);
      // opacity: 1;
      animation: lennon 60s 45s infinite;
      box-shadow: 3px 3px 10px #222;
    }

    @keyframes lennon {
      0% {
        transform: translate(650px, -387px) rotate(10deg);
        opacity: 0;
      }
      5% {
        opacity: 1;
      }
      45% {
        transform: translate(650px, -462px) rotate(10deg);
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        transform: translate(650px, -387px) rotate(10deg);
        opacity: 0;
      }
    }

    &__names {
      animation: scrollUp linear infinite;
      padding-top: 100vh;
      text-align: center;
      z-index: 0;

      div {
        @include _(fs-m);
        @include _(fw-xbold);
        line-height: 2em;
        z-index: 0;
        // color: rgba($grey-90, 0.4);
        // -webkit-text-stroke: 1px rgba($grey-90, 0.5);
        color: rgba($grey-90, 0.6);
        text-shadow: 2px 2px rgba($grey-90, 0.2);
        // white-space: nowrap;
        // span {
        //   white-space: pre;
        // }

        ul {
          opacity: 0.6;
          li {
            @include _(fs-m);
            @include _(fw-xbold);
            list-style: none;
            display: inline-block;
          }
        }
      }
    }

    &__search_button {
      width: 70%;
      height: 213px;
      position: absolute;
      bottom: 18%;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      z-index: 9999;

      .tiltify {
        position: absolute;
        width: 20%;
        bottom: -200px;
        right: 0;
        left: 0;
        margin: 0 auto;
      }

      .rah-hint-wrapper {
        font-weight: 700 !important;
        // left: 104px !important; mac
        left: 62px !important;
        font-family: "SJ Sans", "Open Sans", "Helvetica Neue", "Helvetica",
          "Arial", "sans-serif" !important;
        // top: 63px !important;
      }

      &:before {
        content: "";
        position: absolute;
        // right: 118px; mac
        right: 90px;
        top: 95px;
        height: 50px;
        width: 50px;
        display: inline-block;
        margin-right: 10px;
        mask: url("../../../../img/search_glass.svg") no-repeat 50% 50%;
        mask-size: cover;
        background-color: $primary;
        z-index: 9999;
      }

      input {
        &.search_field {
          @include _(fs-ml);
          @include _(fw-bold);
          @include _(mt-m);
          border-radius: $radius;
          border: 0px;
          padding: 2rem 5rem 2rem 2rem;
          line-height: 2.25rem;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: $grey-10;
          }
        }
      }

      .validation {
        @include _(p-xxs);
        @include _(fw-bold);
        width: 80%;
        margin: 0 auto;
        background: rgba($grey-90, 0.8);
        color: $white;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
      }

      button {
        display: none;
        color: $white;
        padding: 1rem;
        border: 0;
        border-radius: $radius;
        margin: 25px auto 0;
        background: $primary;
      }
    }

    &__select {
      display: block;

      div {
        display: inline-block;
        @include _(mv-s);
        @include _(mh-xxs);
        @include _(fs-m);
        color: $white;
      }

      label {
        @include _(p-xs);
        @include _(fw-bold);
        background-color: $white;
        color: $grey-90;
        border-radius: $radius;

        &.active {
          background-color: $link-color;
          color: $white;
        }
      }

      input {
        display: none;
      }

      span {
        @include _(fs-m);
      }
    }
  }

  @keyframes scrollUp {
    0% {
      transform: translate(0, 0%);
    }
    100% {
      transform: translate(0, -100%);
    }
  }
}
