// body {
//   background-color: #333;
// }

//-----------
// Typography
//-----------

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
}

p {
  @include paragraph;
}

.content-wrapper {
  @include _(pb-m);
}

.left {
  float: left;
}

.right {
  float: right;
}

.hide {
  display: none !important;
}

.b_em {
  @include _(fw-bold);
  font-style: italic;
}

.unpublished {
  @include _(p-m);
  @include _(fs-m);
  border-radius: $radius;
  color: $white;
  background-color: $dark-grey;
  opacity: 0;
  animation-name: unpublished;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes unpublished {
  0% {
    opacity: 0
  };
  100% {
    opacity: 1;
  }
}

a {
  // color: $dark-grey;

  [class*="cta-"] {
      @include btn (
          $btn-padding: .85rem 2rem,
          $btn-radius: $radius,
          $btn-letter-spacing: null
      ) {

          @include _(depth-level-1);
          @include _(ripple);
          @include _(text-center);
          @include _(lh-s);
          @include _(mt-xs);
          vertical-align: top;
          width: 100%;
          background: $white;

          @include breakpoint(small) {

              + [class*="cta-"] {
                  margin-left: 0.5625rem;
              }
          }
      }

      &.cta-primary {
          color: $white;
          background: $primary;
          border-color: $primary;
          border-bottom-color: darken($primary, 10%);
          width: 20%;

          &:hover {
              background: lighten($primary, 10%);
          }
      }

      &.cta-secondary {
          color: $dark-grey;
          border: 1px solid $dark-grey;

          &:hover {
              background: $accent;
              border-color: dark-accent;
              color: $white;
          }
      }
  }

}

button {
  // color: $dark-grey;

  [class*="cta-"] {
      @include btn (
          $btn-padding: .85rem 2rem,
          $btn-radius: $radius,
          $btn-letter-spacing: null
      ) {

          @include _(depth-level-1);
          @include _(ripple);
          @include _(text-center);
          @include _(lh-s);
          @include _(mt-xs);
          vertical-align: top;
          width: 100%;
          background: $white;

          @include breakpoint(small) {

              + [class*="cta-"] {
                  margin-left: 0.5625rem;
              }
          }
      }

      &.cta-primary {
          color: $white;
          background: $primary;
          border-color: $primary;
          border-bottom-color: darken($primary, 10%);

          &:hover {
              background: lighten($primary, 10%);
          }
      }

      &.cta-secondary {
          color: $dark-grey;
          border: 1px solid $dark-grey;

          &:hover {
              background: $accent;
              border-color: dark-accent;
              color: $white;
          }
      }
  }

}

.error {
  @include _(p-xs);
  text-align: center;
  background-color: color-variant($error, 9);
  color: color-variant($error, -5);
  border: 1px solid $error;
  border-radius: $radius;
  width: 80%;
  margin: 0 auto;
}