.fundraiser {
  background: url("../../../../img/fundraiser_bg.png") no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  height: 100vh;

  &_info {
    @include _(mt-l);
    padding: 10px 30px 200px;
    background: rgba($grey-90, 0.5);
    border-top: 1px solid $grey-90;
    border-bottom: 1px solid $grey-90;
    position: relative;
    transform: rotate(-2deg);
    width: 120%;
    position: relative;
    left: -110px;
  }

  &_wrapper {
    position: absolute;
    width: 100%;
    text-align: center;

    .sj_logo {
      margin-top: 20%;
      width: 80%;
      opacity: 1;
      animation: logo 1.5s 1 forwards;
    }

    @keyframes logo {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @function makelongshadow($length, $angle) {
      $val: 0px 0px transparent;
      @for $i from 1 through $length {
        $val: #{$val}, #{$i}px #{$i * $angle}px $grey-50;
      }
      @return $val;
    }

    .username {
      @include _(mt-xxl);
      animation: username 1.5s 1 forwards;

      @keyframes username {
        0% {
          transform: translate(100%, 0);
        }
        80% {
          transform: translate(-1%, 0);
        }
        100% {
          transform: translate(0, 0);
        }
      }

      span {
        @include _(fw-xbold);
        word-wrap: break-word;
        font-size: 80px;
        color: $white;
        line-height: 1em;
        // text-shadow: makelongshadow(20,1);
        // text-shadow: #533d4a 1px 1px, #533d4a 2px 2px, #533d4a 3px 3px,
        //   #533d4a 4px 4px, #533d4a 5px 5px, #533d4a 6px 6px;
        text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
          0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent,
          0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgb(0 0 0 / 40%);
      }
    }

    .amount_raised {
      @include _(mt-l);
      animation: amount 1.5s 1 forwards;

      @keyframes amount {
        0% {
          transform: translate(-100%, 0);
        }
        80% {
          transform: translate(1%, 0);
        }
        100% {
          transform: translate(0, 0);
        }
      }

      span {
        @include _(fw-xbold);
        font-size: 150px;
        color: $white;
        line-height: 1em;
        // text-shadow: makelongshadow(20,1);
        text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
          0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent,
          0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgb(0 0 0 / 40%);
      }

      p {
        @include _(fw-xbold);
        @include _(pt-s);
        @include _(m-none);
        @include _(fs-m);
        color: $white;
        text-shadow: 0 6px 0 transparent, 0 7px 0 transparent,
          0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgb(0 0 0 / 40%);
      }
    }
  }

  &__patients {
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    position: absolute;

    div {
      position: absolute;
      width: 30%;
      line-height: 0;

      img {
        border: 5px solid $white;
        border-radius: $radius;
      }

      span {
        @include _(fs-xs);
        position: absolute;
        background: $white;
        @include _(ph-xxs);
        left: 0;
        bottom: 0;
        line-height: 2em;
      }

      &.elizabeth {
        // transform: translate(100px, 50px) rotate(-10deg);
        left: 100px;
        top: 50px;
      }

      &.jacob {
        transform: translate(650px, -120px) rotate(10deg);
      }

      &.langston {
        transform: translate(100px, 780px) rotate(-10deg);
      }

      &.raven {
        transform: translate(650px, 530px) rotate(10deg);
      }
    }
  }
}
