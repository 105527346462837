//------------------------------------
// Grid
//------------------------------------

// Grid utilities
//---------------------------------------------------------------
@include placeholder("grid-base") {
    display: inline-block;
    float: left;
}

@mixin symmetrical-grid(
    $columns: 3,
    $gutter: 2%,
    $child-element-name: "." + grid-item
) {
    #{$child-element-name} {
        @include _(grid-base);
        width: calc((99.9% - (#{$gutter} * (#{$columns} - 1))) / #{$columns});
        margin-right: $gutter;
        margin-top: $gutter;

        &:nth-child(#{$columns}n + #{$columns}) {
            margin-right: 0;
        }

        &:nth-child(-n + #{$columns}) {
            margin-top: 0;
        }

        &:nth-child(#{$columns}n + 1) {
            clear: left;
        }

        @content;
    }

    @include _(clearfix);
}

@mixin assymmetrical-grid(
    $column-number: 4,
    $columns: (
        "1": 10%,
        "2": 40%,
        "3": 40%,
        "4": 10%
    ),
    $gutter: 2%,
    $child-element-name: "." + grid-item
) {
    #{$child-element-name} {
        @include _(grid-base);
        margin-right: $gutter;
        margin-top: $gutter;

        @each $column, $widths in $columns {
            $column-width: nth($widths, 1);

            &:nth-child(#{$column}) {
                width: calc(
                    #{$column-width} -
                        (
                            #{$gutter} *
                                (#{$column-number} - 1) /#{$column-number}
                        )
                );
            }
        }

        &:last-child {
            margin-right: 0;
        }

        @content;
    }

    @include _(clearfix);
}

// great for disyplaying dynamic data in equal columns without wrapping rows || does not work with fixed height
@mixin quick-column($columns: 2, $gutter: $vr-base) {
    -webkit-columns: $columns;
    -moz-columns: $columns;
    columns: $columns;
    -webkit-column-gap: $gutter;
    -moz-column-gap: $gutter;
    column-gap: $gutter;
}

//---------------------------------------------------------------
// Grid system used with classes for author controlled layout
//---------------------------------------------------------------

$generate-grid-classes: false !default;
$grid-breakpoint: null !default;

$grid-columns: 12 !default;
$grid-gutter: 2% !default;

$parent-element-class: null !default;
$gutter-class-name: gutters !default;

$grid-class-name-prefix: col- !default;
$offset-class-name-prefix: offset- !default;

// Generate Columns
@mixin generate-grid-system {
    // default all columns to 100% on mobile
    [class*="#{$grid-class-name-prefix}"] {
        width: 100%;
    }

    // for the grid system we need to remove the axiomatic spacing if it is turned on
    @if $axiomatic-spacing != off {
        [class*="#{$grid-class-name-prefix}"] {
            margin-top: 0;
        }
    }

    // but we do want columns with gutters to show them in mobile
    .#{$parent-element-class} {
        // default alignment settings for grid || can overwrite at the site level
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &.#{$gutter-class-name} {
            & > [class*="#{$grid-class-name-prefix}"] {
                margin-top: $grid-gutter;
            }
        }
    }

    // use to make grid take effect
    @if $grid-breakpoint != null {
        @media (min-width: $grid-breakpoint) {
            // loop to create number of columns in grid system
            @for $i from 1 through $grid-columns {
                .#{$grid-class-name-prefix}#{$i} {
                    width: 100% / $grid-columns * $i;
                }
                .#{$offset-class-name-prefix}#{$i} {
                    $width: 100% / $grid-columns * $i;
                    margin-left: $width;
                }
            }

            .#{$parent-element-class} {
                // new loop to reset widths when gutters are present
                &.#{$gutter-class-name} {
                    // loop for columns
                    @for $i from 1 through $grid-columns {
                        & > .#{$grid-class-name-prefix}#{$i} {
                            $width: 100% / $grid-columns * $i;
                            width: calc(#{$width} - #{$grid-gutter});
                            margin-left: $grid-gutter / 2;
                            margin-right: $grid-gutter / 2;
                        }
                    }

                    // loop for offsets
                    @for $i from 1 through $grid-columns {
                        & > .#{$offset-class-name-prefix}#{$i} {
                            $width: 100% / $grid-columns * $i;
                            margin-left: calc(#{$width} + #{$grid-gutter/2});
                        }
                    }
                }
            }
        }
    }
}

@if $generate-grid-classes != false {
    @include generate-grid-system;
}
